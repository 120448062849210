<template>
    <div class="main-visual white--text">
        <swiper class="swiper" :options="swiperOptions">
            <swiper-slide v-for="(item, index) in slide" :key="index" class="main-visual__slide">
                <div class="main-visual__slide__inner">
                    <v-container class="container--lg">
                        <div class="main-visual__slide__ani">
                            <h2 v-for="detail in item.title" :key="detail" v-html="detail" class="main-visual__slide__title"></h2>
                        </div>
                    </v-container>
                </div>
                <div class="main-visual__slide__bg" :style="'background-image: url('+item.image+');'"></div>
            </swiper-slide>
            <div class="swiper-control" slot="pagination" @click.prevent>
                <swiper-pagination-primary class="swiper-pagination--light" />
            </div>
        </swiper>
        <div class="main-visual__bottom d-none d-md-block">
            <div class="main-visual__bottom-inner">
                <v-row no-gutters class="main-visual__bottom-card-group">
                    <v-col v-for="(item, index) in quick" :key="index" cols="4">
                        <v-card :color="item.color" dark flat rounded="0" height="100" class="main-visual__bottom-card"
                        :to="item.to ? item.to : null"
                        :href="item.href ? item.href : null"
                        :target="item.href ? '_blank' : null"
                        @click="handleChatClick(item)">
                            <div class="h-100 d-flex align-center pa-16px pa-md-24px px-lg-40px">
                                <v-row align="center" justify="center" class="row--xs">
                                    <v-col cols="auto">
                                        <component :is="item.icon" />
                                    </v-col>
                                    <v-col cols="12" md="" class="text-center text-md-left mt-4px mt-md-0">
                                        <u-tit-default class="tit--sm white--text line-height-1">{{item.title}}</u-tit-default>                                                
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-md-block">
                                        <icon-arrow-primary class="main-visual__bottom-arrow" />
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </div>
        <inquiry-dialog ref="inquiry-dialog" />
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import IconChat from "@/components/publish/styles/icons/icon-chat.vue";
import IconTel from "@/components/publish/styles/icons/icon-tel.vue";
import IconKakao from "@/components/publish/styles/icons/icon-kakao.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

import InquiryDialog from "@/components/client/inquiry/inquiry-dialog.vue";

export default {
    components: {
        Swiper,
        SwiperSlide,
        SwiperPaginationPrimary,
        UTitDefault,
        UTxtDefault,
        UTxtMedium,
        IconChat,
        IconTel,
        IconKakao,
        IconArrowPrimary,

        InquiryDialog,
    },
    props: {},
    data() {
        return {
            swiperOptions: {
                effect: "fade",
                allowTouchMove: true,
                centeredSlides: true,
                spaceBetween: 0,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                speed: 500,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            },
            slide: [
                {
                    title: [
                        "22년 퇴임",
                        "판사출신변호사",
                        "24, 23년 퇴임",
                        "검사출신변호사",
                        "이들이 당신의 목소리를",
                        "듣습니다",
                    ],
                    image: "/images/main/visual2.jpg",
                },
                {
                    title: [
                        "전 성범죄전담판사의 경험",
                        "전 성폭력전담검사의 노하우로",
                        "사건을 해결합니다",
                    ],
                    image: "/images/main/visual3.jpg",
                },
                {
                    title: [
                        "한 번의 실수가",
                        "실형으로 이어져서는",
                        "안됩니다.",
                    ],
                    image: "/images/main/visual4.jpg",
                },
                {
                    title: [
                        "판사, 검사출신 성범죄전문변호사가",
                        "잘못된 혐의, 끝까지 변호합니다",
                    ],
                    image: "/images/main/visual.jpg",
                },
            ],
            quick: [
                {
                    title: "상담 문의",
                    icon: "icon-chat",
                    color: "grey darken-2",
                },
                {
                    title: "전화 상담",
                    icon: "icon-tel",
                    color: "grey darken-3",
                    href: "tel:16600722",
                },
                {
                    title: "카톡 상담",
                    icon: "icon-kakao",
                    color: "grey darken-4",
                    href: "http://pf.kakao.com/_xicPuG/chat",
                },
            ],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            setTimeout(() => {
                AOS.init({
                    once: true,
                    offset: 0,
                    duration: 800,
                    throttleDelay: 100,
                });
            }, 100);
        },
        handleChatClick(item) {
            if (item.title === '상담 문의') {
                this.$refs['inquiry-dialog'].shows = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual{
    width: 100%;
    overflow: hidden;
    position: relative;
    ::v-deep{
        .swiper-pagination{
            bottom: 120px;
        }
    }
    &__slide{
        position: relative;
        &__inner{
            width: 100%;
            height: calc(100vh - var(--header-body-height));
            display: flex;
            align-items: center;
            padding-bottom: 100px;
            box-sizing: border-box;
            ::v-deep{
                .swiper-control {
                    max-width: calc(var(--container-lg));
                }
            }
        }
        &__bg{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            background-image: url(/images/main/visual.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            transform: scale(1.4);
            transform-origin: 70% center;
            transition: 7s ease-in-out;
        }
        &__title{
            font-size: 3.2rem;
            color: #fff;
            line-height: 1.3;
            word-break: keep-all;
            transform: translateY(100%);
            opacity: 0;
            visibility: hidden;
            transition: .7s ease-out;
            ::v-deep{
                >span{
                    color: rgba(255, 255, 255, .4);
                }
            }
        }
        &__ani{
            >h2:nth-child(1){
                transition-delay: .2s;
            }
            >h2:nth-child(2){
                transition-delay: .3s;
            }
            >h2:nth-child(3){
                transition-delay: .4s;
            }
        }
    }
    &__bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &__bottom-inner{
        max-width: 1920px;
        margin: 0 auto;
    }
    &__bottom-card{
        &,
        .main-visual__bottom-arrow{
            transition: .2s ease-out;
        }
        .main-visual__bottom-arrow{
            color: var(--v-grey-lighten3);
        }
    }
    .swiper-slide-active{
        .main-visual__slide__ani>h2{
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
        .main-visual__slide__bg{
            transform: scale(1);
        }
    }
}
@media (min-width: 576px) {
    .main-visual{        
        padding-bottom: 0px;
    }
}
@media (min-width: 768px) {
    .main-visual{
        &__slide{
            &__title{
                font-size: 5rem;
            }
        }        
        ::v-deep{
            .swiper-pagination{                
                left: auto;
                right: var(--container-gutter);
                transform: translate(0, -50%);
                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    width: 50vw;
                    height: 1px;
                    top: 50%;
                    right: -18px;
                    transform: translate(100%, -50%);
                    background-color: #fff;
                }
            }
        }
    }
    .main-visual__bottom-card-group>[class*="col"]{
        position: relative;
        top: 100px;
        opacity: 0;
        visibility: hidden;
        animation: fadeUp .8s ease-out forwards;
        &:nth-child(1){
            animation-delay: .8s;
        }
        &:nth-child(2){
            animation-delay: .9s;
        }
        &:nth-child(3){
            animation-delay: 1s;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual{
        &__slide{
            &__title{
                font-size: 5.2rem;
            }
        }
    }
    .v-application{
        .main-visual__bottom-card:hover{
            background-color: var(--v-primary-base) !important;
            .main-visual__bottom-arrow{
                color: #fff;
            }
        }
    }
}
@media (min-width: 1200px) {
    .main-visual{
        &__slide{
            &__inner{
                height: 100vh;
                padding-bottom: 0;
            }
        }       
        ::v-deep{
            .swiper-pagination{
                top: 50%;
            }
        }
    }
}

@keyframes fadeUp{
    0%{
        top: 100px;
        opacity: 0;
        visibility: hidden;
    }
    100%{
        top: 0;
        opacity: 1;
        visibility: visible;
    }
}
</style>
